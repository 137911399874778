import { Link } from "gatsby"
import React from "react"

const GalleryNav = ({ siteTitle, menuLinks }) => {
  return (
    <div className="row post-content">
      {menuLinks.map((link, index) => (
        <div className="col" key={index}>
          <Link
            style={{ color: `black` }}
            to={link.link}
            activeStyle={{ fontWeight: `900` }}
          >
            <div
              style={{
                padding: "1rem 0",
                textAlign: "center",
                background: "#eee",
                letterSpacing: "2px",
              }}
            >
              {link.name}
            </div>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default GalleryNav
